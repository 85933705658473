"use client"

import { useEffect, useState } from "react"
import Image from "next/image"
import { useRouter } from "next/navigation"
import axios from "axios"
import { event } from "nextjs-google-analytics"
import Marquee from "react-fast-marquee"
import { toast } from "sonner"

import { Card, CardContent } from "@/components/ui/card"

const DesignPartners = () => {
  const [designPartners, setDesignPartners] = useState<any[]>([])
  const router = useRouter()

  const fetchAllDesignPartners = async () => {
    try {
      const response = await axios.get("/api/designer")
      setDesignPartners(response.data.data)
    } catch (error: any) {
      console.error("Error while fetching designers :: ", error)
      toast.error(error.data.error)
    }
  }

  useEffect(() => {
    fetchAllDesignPartners()
  }, [])

  return (
    <section className="container my-20">
      <div className="flex mb-12 flex-col items-center justify-center">
        <h2 className="text-[14px] md:text-[32px] text-[#444] mb-5 text-center">
          Our{" "}
          <span className="text-[20px] md:text-[40px] font-semibold">
            Design Partners
          </span>
        </h2>
      </div>
      <div className="">
        <Marquee
          speed={40}
          pauseOnHover
          gradient
          gradientWidth={70}
          gradientColor="white"
        >
          {designPartners.map((designPartner: any, index: any) => (
            <Card
              key={index}
              className="w-[18rem] me-10 shadow-lg border-none mb-8 cursor-pointer"
              onClick={(e) => {
                event("goto_designer_partner", {
                  category: "designer_partner_instagram",
                  label: designPartner.instagram,
                })

                router.push(`/designer/${designPartner.instagram}`) //changed from designer id to designer instagram
              }}
            >
              <CardContent className="p-3">
                <div className="flex justify-between items-center">
                  <Image
                    src={
                      `${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_URL}${designPartner.image}` ||
                      "/assets/images/apparel_1.jpeg"
                    }
                    alt={designPartner.image}
                    className="w-[30%] h-16 rounded-md object-cover"
                    height={100}
                    width={100}
                  />
                  <p className="px-4 w-[70%]">
                    <span className="text-[12px] md:text-[20px] font-semibold">
                      {designPartner.name}{" "}
                    </span>
                    <br />
                    <span className="text-[11px] md:text-[18px] text-[#a5a5a5]">
                      @{designPartner.instagram}
                    </span>
                  </p>
                </div>
              </CardContent>
            </Card>
          ))}
        </Marquee>
      </div>
    </section>
  )
}

export default DesignPartners
